// import './wdyr';
import React from "react";
import ReactDOM from "react-dom";
import RedTigerApp from './RedTigerApp';
import { unregister } from './registerServiceWorker';
import {AppContainer} from 'react-hot-loader';
import configureStore from './appRedux/store';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/redtiger-style.less';
import { initialApp } from "util/common-util";
import { initializeGTM } from "util/google-tag-manager";

const render = async Component => {
  await configureStore();
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById('root')
  );
};

initialApp()
initializeGTM()

// Do this once
// registerServiceWorker({
//   onSuccess: () => store.dispatch({ type: 'SERVICE_WORKER_INITIALIZE' }),
//   onUpdate: register => store.dispatch({ type: 'SERVICE_WORKER_UPDATED', payload: register }),
// });
unregister();

// Render once
render(RedTigerApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./RedTigerApp', () => {
    render(RedTigerApp);
  });
}
