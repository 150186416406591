export const _TOKEN_ = 'ltkrt';
export const SHOP_FRONT_VIEW = `./shop?psid=`

export const DefaultPaginable = { 
  current: 1, 
  pageSize: 10, 
  showSizeChanger: true, 
  pageSizeOptions: ['10', '20', '50', '100'],
}

export const Variants = {
    FadeVariants: {
        initial: 'hidden',
        animate: 'visible',
        variants: {
            hidden: {  opacity: 0 },
            visible: { opacity: 1 }
        }
    },
    FromBottomVariants: {
        initial: 'hidden',
        animate: 'visible',
        variants: {
            hidden: {  opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 }
        }
    },
}

export const springTransition = {
  type: "spring",
  stiffness: 500,
  damping: 30
};