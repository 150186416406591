import React, { memo, useCallback, useEffect, useState } from 'react'
import { Badge, Grid } from 'antd';
import { useSelector } from 'react-redux';
import { StoreSelector } from 'appRedux/selector';
import { useHistory, useLocation } from 'react-router';
import { useLoginModalDispatch } from 'components/LoginModal';
import useAuth from 'components/hook/useAuth';
import ImgShopping from "../../assets/images/find-icon.svg";

const BottomFloat = () => {

  const history = useHistory();
  const location = useLocation();
  const screens = Grid.useBreakpoint();
  const loginModalDispatch = useLoginModalDispatch();
  const cart = useSelector(StoreSelector.cart)
  const [badgeOffset, setBadgeOffset] = useState([20, 0]);
  const isAuth = useAuth();
  const isShopURL = location.pathname.split('/').includes('shop')
  const dontShopURL = location.pathname.split('/').includes('cart')
  const showBar = !dontShopURL && (isAuth || isShopURL);

  useEffect(() => {
    setBadgeOffset(screens.md ? [20, 0] : [18, -25])
  }, [screens])

  function count() {
    return cart?.reduce((acc, lottery) => acc + (lottery.count ?? lottery.amount), 0) ?? 0;
  }

  const gotoCart = useCallback(() => {
    if (cart.length > 0) {
      if (isAuth) {
        history.push('/cart')
      } else {
        loginModalDispatch({ type: 'show', redirectUrl: '/cart' })
      }
    }
  }, [history, cart, isAuth, loginModalDispatch])

  const gotoProfile = useCallback((path, locationState) => {
    if (isAuth) {
      history.push(path, locationState)
    } else {
      loginModalDispatch({ type: 'show', redirectUrl: path, locationState })
    }
  }, [history, isAuth, loginModalDispatch])

  const gotoProfileAwareShop = useCallback((path, locationState) => {
    if (isShopURL) {
      document.querySelector('.SEARCH-LOTTERY-BOX').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    } else {
      if (isAuth) {
        history.push(path, locationState)
      } else {
        loginModalDispatch({ type: 'show', redirectUrl: path, locationState })
      }
    }
  }, [history, isAuth, loginModalDispatch, isShopURL])

  const colX = isShopURL ? 'col-3' : 'col-4';

  if (!showBar) {
    return null;
  }

  return (
    <div className="bottom-float main-menu-footer">
      <div className="container-md">
        <div className="row g-0">
          {isShopURL && (
            <div className="col-3" onClick={gotoCart}>
              <div className="menu-list active line">
                <i className="feather-icon icon-shopping-cart"></i>
                <Badge count={count()} overflowCount={9999} offset={badgeOffset} className="mb-0">
                  <span>รถเข็น</span>
                </Badge>
              </div>
            </div>
          )}
          <div className={colX} onClick={() => gotoProfile('/members-profile', { menu: 'order' })}>
            <div className="menu-list line">
              <i className="feather-icon icon-clock"></i>
              <span>ประวัติการซื้อ</span>
            </div>
          </div>
          <div className={colX} onClick={() => gotoProfileAwareShop('/members-profile', { menu: 'dealerList' })}>
            <div className="menu-list line">
              <div className="me-2 me-mb-0">
                <img src={ImgShopping} alt="shopping bag" className="sytle-shopping-bag" />
              </div>
              <span>ซื้อเลขเด็ด</span>
            </div>
          </div>
          <div className={colX} onClick={() => gotoProfile('/members-profile', { menu: null })}>
            <div className="menu-list">
              <i className="feather-icon icon-user"></i>
              <span>ข้อมูลส่วนตัว</span>
            </div>
          </div>
          {/* <div className={colX} onClick={() => history.push('/complaint')}>
            <div className="menu-list">
              <i className="feather-icon icon-book"></i>
              <span>ร้องเรียน</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default memo(BottomFloat);
