import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, CommonActionType, REQUEST, SUCCESS, FAILURE } from "../../const/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const CommonAction = {
  getCurrentLotteryDate: () => ({ type: CommonActionType.GET_CURRENT_LOTTERT_DATE[REQUEST] }),
  getCurrentLotteryDateSuccess: payload => ({ type: CommonActionType.GET_CURRENT_LOTTERT_DATE[SUCCESS], payload }),
  getCurrentLotteryDateFailure: payload => ({ type: CommonActionType.GET_CURRENT_LOTTERT_DATE[FAILURE], payload }),
  getBankList : () => ({ type: CommonActionType.GET_BANK_LIST[REQUEST] }),
  getBankListSuccess : payload => ({ type: CommonActionType.GET_BANK_LIST[SUCCESS], payload }),
  getBankListFailure : payload => ({ type: CommonActionType.GET_BANK_LIST[FAILURE], payload }),
}

