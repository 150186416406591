import ModalConfirm from 'components/ModalConfirm';
import React, { createContext, useContext, useReducer } from 'react'
import { useHistory } from 'react-router';

export const LoginModaCtxDispatcher = createContext();
export const LoginModaCtxValue = createContext();

const initialState = { visible: false };
const reducer = (state, action) => {
  switch (action.type) {
    case 'show': return {
      ...state,
      visible: true,
      redirectUrl: action.redirectUrl,
      locationState: action.locationState,
    };
    case 'hidden': return { ...initialState };
    default: return state;
  }
}

const LoginModal = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  function gotoLoginPage() {
    hideModal()
    const { redirectUrl, locationState } = state;
    history.push('/sign-up', { redirectUrl, locationState })
  }

  function hideModal() {
    dispatch({ type: 'hidden' })
  }

  return (
    <LoginModaCtxDispatcher.Provider value={dispatch}>
      <LoginModaCtxValue.Provider value={state}>
        <>
          {children}
          <ModalConfirm 
            visible={state.visible}
            title="คุณยังไม่ได้เข้าสู่ระบบ"
            message="ต้องการเข้าสู่ระบบ เพื่อทำการจองเลขเด็ด หรือไม่"
            okText="เข้าสู่ระบบ"
            onOk={gotoLoginPage}
            onCancel={hideModal}
            forceRender />
        </>
      </LoginModaCtxValue.Provider>
    </LoginModaCtxDispatcher.Provider>
  )
}

export default LoginModal;

export const useLoginModal = () => {
  return useContext(LoginModaCtxValue);
}
export const useLoginModalDispatch = () => {
  return useContext(LoginModaCtxDispatcher);
}