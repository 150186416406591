import { FAILURE, REQUEST, StoreActionType as Type, SUCCESS } from 'const/ActionTypes'

export const StoreAction = {
  fetchLottery: (payload, onComplete) => ({ type: Type.SHOP_FETCH_LOTTERY[REQUEST], payload, onComplete }),
  fetchLotterySuccess: payload => ({ type: Type.SHOP_FETCH_LOTTERY[SUCCESS], payload }),
  fetchLotteryFailure: payload => ({ type: Type.SHOP_FETCH_LOTTERY[FAILURE], error: payload }),
  
  fetchLotterySet: payload => ({ type: Type.SHOP_FETCH_LOTTERY_SET, payload }),

  lotteryNumberChange: payload => ({ type: Type.SHOP_LOTTERY_NUMBER_CHANGE, payload }),
  addCart: payload => ({ type: Type.SHOP_ADD_LOTTERY_TO_CART, payload }),
  removeCart: payload => ({ type: Type.SHOP_REMOVE_LOTTERY_FROM_CART, payload }),
  clearOrder: () => ({ type: Type.SHOP_CLEAR_ORDER }),
}