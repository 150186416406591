import { MemberActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
    loading: false,
    member: {},
    rewardList: [],
    shopDetail: {},
};

export default (state = INIT_STATE, { type, payload = {} }) => {
    switch (type) {
        case MemberActionType.GET_DETAIL[REQUEST]:
        case MemberActionType.UPDATE_MEMBER[REQUEST]:
        case MemberActionType.REWARD_LIST[REQUEST]:
        case MemberActionType.SHOP_DETAIL[REQUEST]:
            return { ...state, loading: true }

        case MemberActionType.GET_DETAIL[SUCCESS]:
        case MemberActionType.UPDATE_MEMBER[SUCCESS]:
        case MemberActionType.REWARD_LIST[SUCCESS]:
        case MemberActionType.SHOP_DETAIL[SUCCESS]:
            return { ...state, loading: false, ...payload }

        case MemberActionType.GET_DETAIL[FAILURE]:
        case MemberActionType.UPDATE_MEMBER[FAILURE]:
        case MemberActionType.REWARD_LIST[FAILURE]:
        case MemberActionType.SHOP_DETAIL[FAILURE]:
            return { ...state, loading: false, error: payload }

        default:
            return state;
    }
}
