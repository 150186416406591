import { Button, Modal, Space } from 'antd';
import React, { memo } from 'react'

const ModalConfirm = ({ 
  title, message, 
  onOk, okText, 
  onCancel, cancelText,
  ...props
}) => {
  return (
    <Modal title={null} footer={null}
      onCancel={onCancel}
      centered
      {...props}
    >
      <div className="text-center">
        <h5>{title}</h5>
        <p className="pt-2">{message}</p>
        <div className="py-3">
          <Space size="middle">
            <Button style={{ width: 120 }} type="primary" onClick={() => onOk()}>
              {okText}
            </Button>
            <Button style={{ width: 120 }} ghost type="primary" onClick={() => onCancel()}>
              {cancelText}
            </Button>
          </Space>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ModalConfirm);

ModalConfirm.defaultProps = {
  onOk: () => {}, okText: 'ตกลง', 
  onCancel: () => {}, cancelText: 'ยกเลิก',
}