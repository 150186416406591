// Immutate every thing [e v e r y ...t h i n g]
import helperUpdate from 'immutability-helper'
import { isNotNullish } from './common-util';

const extract = (array, keyField, changeVarItem) => {
  const { [keyField]: key, ...updateFields } = changeVarItem;
  if (key === undefined) {
    return [-1];
  }
  // process extract
  let index;
  if (typeof key === 'string' || typeof key === 'number') {
    index = array.findIndex(o => o[keyField] === key);
  } else if (typeof key === 'function') {
    index = array.findIndex(o => key(o));
  }
  // else in the future Naaaaaaaa.
  return [index, updateFields];
}

const resolve = (data, ...fields) => {
  if (!data) return;
  if (data && fields && !fields[0]) return data;

  return fields
    .reduce(
      (results, field) =>
      (field && field.indexOf('.') > -1
        ? [...results, ...field.split('.')]
        : [...results, field])
      , [])
    .reduce(
      (result, field) => (result ? result[field] : undefined),
      data
    );
}

/*
* Explain ::
* 1. updateOption :: config and updated state payload ( 'state' field )
*    1.1 ) path [string]          :: nested path of target field that will be updated.
*          - {key} syntax means   :: replace this with real unique id then remove it from updated state payload. ( ps. prevent update unexpect unique key )
*          - {{key}} syntax means :: like {key} but it will reserve this field to updated state.
*    1.2 ) pathParams [object]    :: object that store path params value which is used to find the index in update process.
*    1.3 ) action [String]        :: action to apply with update process. ( ps. follow immutability-helper lib. ) 
                                     in case of not specific we will use $apply then.
*    1.4 ) state [object]  :: store updated value.
* 2. currentState :: is current state
*/
export const getImmuUpdatedPattern = (updateOption, currentState) => {
  const { path, pathParams, action, state } = updateOption;
  const travelPath = [];
  const pathIndexParamRegex = /^\{(.*)\}$/;

  let template, isPathWrong = false;
  if (!path) {
    template = {};
  } else {
    template = path.split('.').reduce(
      (pathObj, field, index) => {
        // when some finding data in array not found.
        if (isPathWrong) return pathObj;
        // solve index path param
        if (pathIndexParamRegex.test(field)) {
          let deleteKeyFromUpdatedState = true;
          let keyField = pathIndexParamRegex.exec(field)[1];
          if (pathIndexParamRegex.test(keyField)) { // {{key}} syntax means :: reserve this field to update state
            deleteKeyFromUpdatedState = false;
            keyField = pathIndexParamRegex.exec(keyField)[1];
          }
          const arrayData = resolve(currentState, travelPath.join('.'));
          field = extract(arrayData, keyField, pathParams || state)[0]; // in this case 'field' is index
          if (field === -1) { // can not find specific object
            isPathWrong = true;
            return {}; // return empty object for not update anything
          }
          if (deleteKeyFromUpdatedState && typeof state === 'object') {
            delete state[keyField]; // remove PK out for prevent update reference key
          }
        }

        // create nested updated template
        (index === 0
          ? pathObj
          : resolve(pathObj, travelPath.join('.'))
        )[field] = {};

        // store travel path
        travelPath.push(field);

        return pathObj;
      }, {}
    );
  }

  // assign command update
  if (!isPathWrong) {
    if (action) {
      const targetUpdate = resolve(template, travelPath.join('.'));
      // check if custom operations
      if (transformOperation.includes(action)) {
        const targetObject = resolve(currentState, travelPath.join('.'));
        const { transform, actionName = action } = transformOperations[action];
        const transformState = transform(targetObject, state);
        if (transformState === undefined || transformState === null) {
          return (template = {}, template);
        }
        targetUpdate[actionName] = transformState;
      } else {
        const targetObject = resolve(currentState, travelPath.join('.'));
        const nState = typeof state === 'function'
          ? state(targetObject) ?? state
          : state;
        targetUpdate[action] = nState;
      }
    } else { // $apply
      if (typeof state === 'function') {
        const backwardOnePath = [...travelPath];
        const target = backwardOnePath.splice(-1, 1)[0];
        const parentTarget = resolve(template, backwardOnePath.join('.'));
        parentTarget[target] = state;
      }
    }
  }

  // console.log({template, isPathWrong, path, updateOption})
  return template;
}


/* PROCESS */
/*
** updateOptions: { action, path, pathParams, state }  >> for more detail above at line 20
*/
const processUpdate = (state, ...updateOptions) => {
  return updateOptions.filter(v => v).reduce((newState, updateOption) => {
    const immUpdatedPattern = getImmuUpdatedPattern(updateOption, state);
    return helperUpdate(newState, immUpdatedPattern); // use immutability-helper #HERE
  }, state);
}

export const customUpdateAction = (action, state, updateOptions) => {
  return processUpdate(state, ...updateOptions.filter(v => v).map(us => ({ ...us, action })));
}
/* END PROCESS */


/* FUNCTIONS */
export const manualUpdate = (...params) => helperUpdate(...params);

export const merge = (state, ...updateOptions) => {
  return customUpdateAction('$merge', state, updateOptions);
}
export const set = (state, ...updateOptions) => {
  return customUpdateAction('$set', state, updateOptions);
}
export const push = (state, ...updateOptions) => { // add object to last index of array
  return customUpdateAction('$push', state, updateOptions);
}
export const unshift = (state, ...updateOptions) => { // add object to first index of array
  return customUpdateAction('$unshift', state, updateOptions);
}
/*
*  splice can use to remove or add object(s) to array
*    - syntax :: [  [] << cmds  ]
*    - remvoe :: [removeIndex, 1]
*    - insert :: [addIndex, 0, OBJECT]
*  can do multiple command in onec operate
*    - move   :: [[moveIndex, 1], [toIndex, 0, list[moveIndex]]]
*             ** [____remove____  ____________insert___________]
*/
export const splice = (state, ...updateOptions) => {
  return customUpdateAction('$splice', state, updateOptions);
}
export const arrayRemove = (state, ...updateOptions) => {
  return customUpdateAction(REMOVE_ACTION, state, updateOptions);
}
/* END FUNCTIONS */


/* Utils Cooperate */
const REMOVE_ACTION = '$remove';

const transformOperations = {
  /* state: { index: number | function } */
  [REMOVE_ACTION]: {
    actionName: '$splice',
    transform: (data, statePayload) => {
      const { index } = statePayload;
      const removeIndex = (typeof index === 'function') ? (index(data) ?? -1) : index;

      if (
        (removeIndex === undefined || removeIndex === null)
        || (Array.isArray(removeIndex) && removeIndex.filter(isNotNullish).length === 0)
        || removeIndex < 0
      ) return null;

      const removeTemplate = Array.isArray(removeIndex)
        ? removeIndex.filter(isNotNullish).map(index => [index, 1])
        : [[removeIndex, 1]];

      return removeTemplate;
    }
  },
  $push: {
    transform: (_, statePayload) => {
      return (typeof statePayload === 'object' && !Array.isArray(statePayload)) ? [statePayload] : statePayload;
    }
  }
}

const transformOperation = Object.keys(transformOperations);
/* END Utils Cooperate */


const ImmuUtil = {
  update: processUpdate, manualUpdate,
  merge, set, push, unshift, splice, arrayRemove,
  // new form functional
  PUSH: '$push',
  UNSHIFT: '$unshift',
  REMOVE_OR_INSERT: '$splice',
  MERGE: '$merge',
  SET: '$set',
  REMOVE: REMOVE_ACTION,
}

export default ImmuUtil;



/*
* Example ::
*/
// case InvestmentRecommendType.UPDATE_STATE:
//     switch (payload.target) {
//         case 'main-detail': 
//             return ImmuUtil.merge(state, 
//                 { path: 'data', state: payload.state }
//             );
//         case 'add-strategy': 
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.APPEND, 
//                     path: 'data.strategies', 
//                     state: [payload.state],
//                 },
//                 { 
//                     path: 'data.strategies',
//                     state: strategies => order(strategies, 'displaySeq'),
//                 }
//             );
//         case 'remove-strategy': 
//             return ImmuUtil.update(state,
//                 { 
//                     action: ImmuUtil.REMOVE_OR_INSERT,
//                     path: 'data.strategies', 
//                     state: payload.state,
//                 },
//                 { 
//                     path: 'data.strategies',
//                     state: strategies => order(strategies, 'displaySeq'),
//                 }
//             );
//         case 're-order-strategy': 
//             return ImmuUtil.update(state,
//                 { 
//                     action: ImmuUtil.SET,
//                     path: 'data.strategies', 
//                     state: payload.state,
//                 },
//                 {
//                     path: 'data.strategies', 
//                     state: strategies => order(strategies, 'displaySeq'),
//                 }
//             );
//         case 'strategy-detail': 
//             return ImmuUtil.merge(state, 
//                 { path: 'data.strategies.{allocStrategyType}', state: payload.state }
//             );
//         case 'main-fund': 
//             return ImmuUtil.merge(state, 
//                 { 
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}', 
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 }
//             );
//         case 'al-fund': 
//             return ImmuUtil.merge(state, 
//                 { 
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds.{alternativeFundId}',
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 }
//             );
//         case 'add-main-fund': 
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.APPEND,
//                     path: 'data.strategies.{{allocStrategyType}}.funds', 
//                     pathParams: payload.pathParams,
//                     state: [payload.state],
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds', 
//                     pathParams: payload.pathParams,
//                     state: funds => order(funds, 'displaySeq')
//                 }
//             );
//         case 'remove-main-fund': 
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.REMOVE_OR_INSERT,
//                     path: 'data.strategies.{allocStrategyType}.funds', 
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds', 
//                     pathParams: payload.pathParams,
//                     state: funds => order(funds, 'displaySeq')
//                 }
//             );
//         case 'add-al-fund': 
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.APPEND,
//                     path: 'data.strategies.{{allocStrategyType}}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: [payload.state],
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: alternativeFunds => order(alternativeFunds, 'displaySeq')
//                 }
//             );
//         case 'remove-al-fund': 
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.REMOVE_OR_INSERT,
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: alternativeFunds => order(alternativeFunds, 'displaySeq')
//                 },
//             );
//         case 'move-main-fund':
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.SET,
//                     path: 'data.strategies.{allocStrategyType}.funds', 
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds', 
//                     pathParams: payload.pathParams,
//                     state: alternativeFunds => order(alternativeFunds, 'displaySeq')
//                 },
//             );
//         case 'move-al-fund':
//             return ImmuUtil.update(state, 
//                 { 
//                     action: ImmuUtil.SET,
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: payload.state,
//                 },
//                 {
//                     path: 'data.strategies.{allocStrategyType}.funds.{allocFundId}.alternativeFunds', 
//                     pathParams: payload.pathParams,
//                     state: alternativeFunds => order(alternativeFunds, 'displaySeq')
//                 },
//             );

//         default: return state;
//     }