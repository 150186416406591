import { takeLatest, call, put, takeLeading } from 'redux-saga/effects'
import { OrderAction } from '../actions/Order'
import { OrderActionType, REQUEST } from '../../const/ActionTypes'
import { OrderAPI } from '../services/Order'
import Notification from '../../util/notification'
import { MemberAPI } from 'appRedux/services/Member'

const getOrderDetail = function* ({ payload }) {
  try {
    const response = yield call(OrderAPI.getOrderDetail, payload)
    const orderDetail = {
      ...response.data.result
    }
    yield put(OrderAction.getOrderDetailSuccess({ orderDetail }));
  } catch (error) {
    yield put(OrderAction.getOrderDetailFailure(error));
  }
}

const uploadProofPayment = function* ({ payload, resolve }) {
  try {
    yield call(MemberAPI.uploadProofPayment, payload);
    yield put(OrderAction.uploadProofPaymentSuccess());
    Notification.alertInfo("อัพโหลดหลักฐานสำเร็จ");
    resolve && resolve(true)
  } catch (error) {
    console.log(error)
    resolve && resolve(false)
    yield put(OrderAction.uploadProofPaymentFailure(error));
    Notification.alertError("อัพโหลดหลักฐานไม่สำเร็จสำเร็จ");
  }
}

const addOrder = function* ({ payload }) {
  try {
    const response = yield call(OrderAPI.addOrder, payload);
    const result = response.data.result;
    yield put(OrderAction.addOrderSuccess(result));
  } catch (error) {
    console.log(error)
    yield put(OrderAction.addOrderFailure(error));
  }
}

export default [
  takeLatest(OrderActionType.GET_ORDER_DETAIL[REQUEST], getOrderDetail),
  takeLatest(OrderActionType.UPLOAD_PROOF_PAYMENT[REQUEST], uploadProofPayment),
  takeLeading(OrderActionType.ADD_ORDER[REQUEST], addOrder),
];