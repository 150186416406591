import { OrderActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes'

export const OrderAction = {
    getOrderDetail: payload => ({ type: OrderActionType.GET_ORDER_DETAIL[REQUEST], payload }),
    getOrderDetailSuccess: payload => ({ type: OrderActionType.GET_ORDER_DETAIL[SUCCESS], payload }),
    getOrderDetailFailure: error => ({ type: OrderActionType.GET_ORDER_DETAIL[FAILURE], error }),

    uploadProofPayment: (payload, resolve) => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[REQUEST], payload, resolve }),
    uploadProofPaymentSuccess: payload => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[SUCCESS], payload }),
    uploadProofPaymentFailure: error => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[FAILURE], error }),

    getOrderList: payload => ({ type: OrderActionType.GET_ORDER_LIST[REQUEST], payload }),
    getOrderListSuccess: payload => ({ type: OrderActionType.GET_ORDER_LIST[SUCCESS], payload }),
    getOrderListFailure: error => ({ type: OrderActionType.GET_ORDER_LIST[FAILURE], error }),

    addOrder: (payload, resolve) => ({ type: OrderActionType.ADD_ORDER[REQUEST], payload, resolve }),
    addOrderSuccess: payload => ({ type: OrderActionType.ADD_ORDER[SUCCESS], payload }),
    addOrderFailure: error => ({ type: OrderActionType.ADD_ORDER[FAILURE], error }),

    initOrdering: () => ({ type: OrderActionType.INIT_ORDERING }),
}