import { OrderActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const orderDetailInitial = {
  customer: {},
  items: [],
}

const INIT_STATE = {
  loading: false,
  uploading: false,
  items: [],
  totalItem: 0,
  orderDetail: orderDetailInitial,
  orderSuccessPayload: undefined,
  dirty: false,
};

export default (state = INIT_STATE, { type, payload = {}, error, resolve }) => {
  switch (type) {
    case OrderActionType.GET_ORDER_LIST[REQUEST]:
      if (payload.page === 1)
        return { ...state, loading: true, items: [] }
      else 
        return { ...state, loading: true }
    case OrderActionType.GET_ORDER_DETAIL[REQUEST]:
      return { ...state, loading: true, orderDetail: orderDetailInitial, dirty: true, }

    case OrderActionType.GET_ORDER_DETAIL[SUCCESS]:
    case OrderActionType.GET_ORDER_LIST[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case OrderActionType.GET_ORDER_LIST[FAILURE]:
    case OrderActionType.GET_ORDER_DETAIL[FAILURE]:
      return { ...state, loading: false, error }

    case OrderActionType.INIT_ORDERING:
      return { ...state, orderSuccessPayload: undefined }

    case OrderActionType.ADD_ORDER[REQUEST]:
      return { ...state, loading: true }
    case OrderActionType.ADD_ORDER[SUCCESS]:
      return { ...state, loading: false, orderSuccessPayload: payload }
    case OrderActionType.ADD_ORDER[FAILURE]:
      return { ...state, loading: false }

    case OrderActionType.UPLOAD_PROOF_PAYMENT[REQUEST]:
      return { ...state, uploading: true }
    case OrderActionType.UPLOAD_PROOF_PAYMENT[SUCCESS]:
    case OrderActionType.UPLOAD_PROOF_PAYMENT[FAILURE]:
      return { ...state, uploading: false }

    default:
      return state;
  }
}
