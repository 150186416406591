import React, { memo, useEffect, useState } from 'react'
import { Button, Modal } from 'antd';
import useControlVisible from 'components/hook/useControlVisible';
import ads from 'assets/images/ads/ads.jpg';
import { useLocation } from 'react-router';

const Storage = {
  Window: window,
  Session: sessionStorage,
  Local: localStorage,
}

const AdsModal = (props) => {

  const whitelist = ['/main']
  const [adLoaded, setAdLoaded] = useState(false)
  const location = useLocation()
  const [storage] = useState(() => Storage[props.storage] ?? Storage.Window)
  const [adsModalVisible, , [, hideAdsModal]] = useControlVisible(!storage['hide_ads'])

  const onCloseAds = () => {
    hideAdsModal()
    storage['hide_ads'] = true
  }

  const renderCloseButton = () => (
    <ButtonDelay
      key="back"
      onClick={onCloseAds}
      delay={1150}
      disabled={!adLoaded}>
      ปิด
    </ButtonDelay>
  )

  const isWhitelistPage = () => whitelist.includes(location.pathname)

  const onAdImageLoaded = () => setAdLoaded(true)

  return (
    <Modal
      closable={false}
      maskClosable={false}
      wrapClassName="modal-p-0"
      title="ประกาศ"
      visible={adsModalVisible && isWhitelistPage()}
      onOk={onCloseAds}
      onCancel={onCloseAds}
      footer={[renderCloseButton()]}
    >
      <img
        className="w-100 user-select-none"
        src={ads}
        onLoad={onAdImageLoaded}
        alt="advertises" />
    </Modal>
  )
}

export default memo(AdsModal)

const ButtonDelay = ({ trigger, delay, disabled: disabledProp, ...props }) => {
  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    setDisabled(true)
    const timeout = setTimeout(() => setDisabled(false), delay ?? 1000)
    return () => clearTimeout(timeout)
  }, [trigger, delay])

  return <Button {...props} disabled={disabled || disabledProp}>{props.children}</Button>
}