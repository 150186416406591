import React from 'react'
import { notification } from 'antd'
import { AlertTwoTone, WarningTwoTone, BookTwoTone, ExceptionOutlined } from '@ant-design/icons'

let messageTemp, messageTempWatcher;

/* 
* support string and object config
* object config format 
* { 
*   msg: 'string|ReactNode', <-- or msg: [{ label, msg }] for validate format.
*   title: 'string|ReactNode',
*   config: { ...antd notify config } 
* }
*/
const alertInfo = (config) => central(Notify.INFO, config);
const alertError = (config) => central(Notify.ERROR, config);
const alertWarning = (config) => central(Notify.WARN, config);
const alertValidate = (config) => prepareValidation(config);

function central(alert, config = {}) {
    const { title, icon, style } = alert;
    let msg, customTitle, overrideConfig; 

    if ( typeof config === 'string' ) {
        msg = config;
        overrideConfig = {};
    } else {
        msg = config['msg'];
        customTitle = config['title'];
        overrideConfig = config['config'] || {};
    }

    // if ( msg === undefined ) return; // do nothing [old version]
    if ( msg === undefined ) msg = ''; // just alert only title

    const notifyDetail = {
        style,
        icon,
        message: customTitle || title,
        description: msg,
        ...overrideConfig,
    };

    // should notice or not ?
    let notice = false;
    if ( !messageTemp ) {
        notice = true;
    } else if (
        notifyDetail.message !== messageTemp.message || 
        notifyDetail.description !== messageTemp.description
    ) {
        notice = true;
    }

    if ( notice ) {
        // alert notice
        notification.open(notifyDetail);
        // prevent alert duplicate message
        messageTemp = {
            message: notifyDetail.message,
            description: notifyDetail.description,
        };
        
        clearTimeout(messageTempWatcher);
        messageTempWatcher = setTimeout(() => {
            messageTemp = undefined;
        }, 4 * 1000);
        // end prevent alert duplicate message
    }

}

function prepareValidation(config) {
    if ( typeof config === 'string' || typeof config['msg'] === 'string' ) {
        central(Notify.VALIDATE, config)
    } else {
        const msgList = config['msg'].map(msg => (
            <React.Fragment key={Math.random()}>
                <div className="validate-label">{msg.label}</div>
                <div className="validate-divider">:</div>
                <div className="validate-msg">{msg.msg}</div>
            </React.Fragment>
        ));
        const msg = (<div className="validate-container">{msgList}</div>);
        central(Notify.VALIDATE, { ...config, msg });
    }
}

// type
const Notify = {
    INFO: {
        type: 'info',
        title: 'Information',
        style: {
            backgroundImage: 'linear-gradient(90deg, rgb(44, 172, 220) 0%, rgb(0, 166, 230) 100%)'
        },
        icon: <BookTwoTone twoToneColor="skyblue"/>,
    },
    ERROR: {
        type: 'error',
        title: 'Something Went Wrong',
        style: {
            backgroundImage: 'linear-gradient(90deg, #ff6666 0%, #ff6666)'
        },
        icon: <AlertTwoTone twoToneColor="white"/>,
    },
    WARN: {
        type: 'warning',
        title: 'Warning',
        style: {
            backgroundImage: 'linear-gradient(90deg, rgb(234, 205, 62) 0%, rgb(255, 239, 97))'
        },
        icon: <WarningTwoTone twoToneColor="#fff176"/>,
    },
    VALIDATE: {
        type: 'validate',
        title: 'Validation',
        style: {
            backgroundImage: 'linear-gradient(90deg, rgb(243, 114, 35) 0%, #FFA726)'
        },
        icon: <ExceptionOutlined style={{ color: 'white' }}/>,
    },
};

// defaule config
notification.config({
    placement: 'topRight',
    top: 30,
    duration: 5,
});

export default  {
    alertInfo,
    alertError,
    alertWarning,
    alertValidate,
};