import dayjs from 'dayjs'
import { message } from 'antd'
import Notification from './notification'

const thaiMonths = [
  'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

export function isMobileDevice() {
  let isMobile = false;
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
  }
  return isMobile;
}

export function applyPaging(dataSource, paginable) {
  const { current, pageSize } = paginable;
  var offset = (current - 1) * pageSize;
  return dataSource.slice(offset, offset + pageSize);
}

export function toPaginable(paginable) {
  return {
    page: paginable.current,
    perPage: paginable.pageSize
  }
}

export const clazz = (...clazzes) => {
  return clazzes.reduce((r, o) => {
    return typeof o === 'string' || typeof o === 'undefined'
      ? `${r} ${o || ''}`
      : o.cond
        ? `${r} ${o.clz}`
        : o.else ? `${r} ${o.else}` : r;
  }, '').trim();
}

export const convertToFullThaiFormat = (date, format = 'D M YYYY') => {
  if (!date) return date;
  return dayjs(date)
    .add(543, 'year')
    .format(format)
    .replace(/\s+(\d+)\s+/, (_, month) => ` ${thaiMonths[month - 1]} `);

}

export const convertToFullEngFormat = (date, format = 'D MMMM YYYY') => {
  if (!date) return date;
  return dayjs(date).format(format);

}

export function escapeRegExp(text) {
  if (!text) return;
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function isEmpty(val) {
  return val === null || val === undefined || val === ''
    || (Array.isArray(val) && val.length === 0);
}

export const jsonPretty = (text = '') => {
  try {
    const jsonObj = JSON.parse(text.trim());
    text = JSON.stringify(jsonObj, null, "\t");
  } catch (error) { }
  return text;
}

export const copyTextToClipBoard = (text = '', config = {}) => {
  text = text.trim();
  if (config.autoFormatJson) {
    text = jsonPretty(text);
  }

  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);

  message.success('คัดลอกแล้ว !');
  return result;
}

export const scrollToRight = (selector, offset = 0) => {
  const elem = document.querySelector(selector);
  if (elem) {
    elem.scrollTo({
      left: elem.offsetWidth + offset,
      behavior: 'smooth',
    })
  }
}

export const scrollIntoView = (selector, opt = { behavior: 'smooth' }) => {
  const elem = typeof selector === 'string'
    ? document.querySelector(selector) : selector;
  if (elem) {
    elem.scrollIntoView(opt);
  }
}

export const formatPhoneNumber = (phone) => {
  if (!phone) return phone;
  return phone.trim().replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{4})/, (_, f, s, t) => `${f}-${s}-${t}`)
}

export const removeFormatPhoneNumber = (phone) => {
  if (!phone) return phone;
  return phone.trim().replace(/-/g, '')
}

export const isElementInViewport = (element, offset = {}) => {

  if (!element) return true;

  const {
    bottom: offsetBottom = 0,
  } = offset;

  var top = element.offsetTop;
  var left = element.offsetLeft;
  var width = element.offsetWidth;
  var height = element.offsetHeight;

  while (element.offsetParent) {
    element = element.offsetParent;
    top += element.offsetTop;
    left += element.offsetLeft;
  }

  // console.log({
  //     lay: { top, left, width, height },
  //     win: { y: window.pageYOffset, x: window.pageXOffset, inHeight: window.innerHeight, inWidth: window.innerWidth },
  //     topCheck: top < (window.pageYOffset + window.innerHeight),
  //     leftCheck: left < (window.pageXOffset + window.innerWidth),
  //     bottomCheck: (top + height) > (window.pageYOffset + offsetBottom),
  //     rightCheck: (left + width) > window.pageXOffset,
  // })

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height) > (window.pageYOffset + offsetBottom) &&
    (left + width) > window.pageXOffset
  );
}

export const throttleSingleEvent = (func, interval) => {
  let timeout;
  return (...args) => {
    const context = this;
    const openIncomingCall = () => {
      timeout = false
    }
    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(openIncomingCall, interval);
    }
  }
}

export const loadImage = (imgElem, image) => {
  if (!image || !imgElem) return;
  const reader = new FileReader();
  reader.onload = function (e) {
    imgElem.src = e.target.result;
  };
  reader.readAsDataURL(image);
}

export const lineLink = lineId => {
  if (!lineId) return lineId;
  lineId = lineId.trim();
  if (lineId[0] === '@') {
    return `https://line.me/ti/p/%40${lineId.replace(/^@/, '')}`
  } else {
    return `https://line.me/ti/p/~${lineId}`
  }
}

export const convertLotteryNumberToSearchParams = (lottery, ...initial) => {
  return (lottery || 'XXXXXX').substr(0, 6).split('')
    .map(d => /\d/.test(d) ? +d : null)
    .reduce((acc, d, i) => {
      acc[`digit${i + 1}`] = d;
      return acc;
    }, initial.reduce((r, c) => ({...r, ...c}), {}))
}

export const handleResponse = (response, autoAlertError = true) => {
  const status = response.data.status
  if (status === "0") {
    return [response.data.result, undefined, response]
  } else {
    const errorMessage = response.data.message;
    if (autoAlertError) {
      Notification.alertError(errorMessage)
    }
    return [undefined, errorMessage, response]
  }
}

export const makeBrowserDownload = (byteFile, filename, type) => {
  const blobFile = new Blob([byteFile], { type });
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.setAttribute('type', 'hidden');
  link.href = window.URL.createObjectURL(blobFile);
  link.download = filename;
  link.click();
  link.remove();
}

export function filterOnlyNumber(event) {
  return event.target?.value?.match(/\d+(?:\.\d+)?/g)?.[0];
}

export function callbackOnlyNumber(callback) {
  return event => callback(event.target?.value?.match(/\d+(?:\.\d+)?/g)?.[0] ?? '');
}

export function onlyNumber(event) {
  if (!/\d/.test(event.key)) {
    event.preventDefault()
    return false;
  }
  return true;
}

export function addHiddenField(object, field, value) {
  if (!object[field]) {
    Object.defineProperty(object, field, {
      enumerable: false,
      writable: true,
      configurable: true,
      value: value,
    });
  } else {
    object[field] = value;
  }
}

export const lotteryColors = {
  1: { labelColor: 'tag-mumber number-red' },
  2: { labelColor: 'tag-mumber number-yellow' },
  3: { labelColor: 'tag-mumber number-blue' },
  4: { labelColor: 'tag-mumber number-pink' },
  5: { labelColor: 'tag-mumber number-green' },
}

export function getLabelLotteryColor(amount) {
  if (amount > 5) {
    return lotteryColors[1].labelColor;
  }
  return lotteryColors[amount]?.labelColor;
}

export function paginationAdapter(paginable, toServer = true) {
  if (toServer) {
    const page = paginable.page ?? paginable.current;
    const perPage = paginable.perPage ?? paginable.pageSize;
    paginable = { page, perPage };
    return paginable;
  } else {
    const current = paginable.current ?? paginable.page;
    const pageSize = paginable.pageSize ?? paginable.perPage;
    const total = paginable.total ?? paginable.totalItem;
    paginable = { ...paginable, current, pageSize, total };
    delete paginable.page;
    delete paginable.perPage;
    delete paginable.totalItem;
    return paginable;
  }
}

export const initialApp = () => {
  console.json = (text) => console.log(jsonPretty(text));
}

export const isNotNullish = v => v !== null && v !== undefined
export const isNotEmpty = v => v !== null && v !== undefined && v !== ''

export const getPromise = () => {
  let resolve;
  const promise = new Promise(r => resolve = r);
  return [promise, resolve];
}

export const getUrlQueryParams = (urlSearch, ...params) => {
  const result = {};
  if (urlSearch) {
    const queryParams = new URLSearchParams(urlSearch)
    params.forEach(param => result[param] = queryParams.get(param))
  }
  return params.length === 1 ? result[params[0]] : result;
}

export const isImageTypeAllowed = (ext) => ['pdf', 'jpg', 'png', 'bmp', 'jpeg'].includes(ext)