import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import orderSaga from "./Order";
import commonSaga from "./Common";
import memberSaga from "./Member";
import storeSaga from "./Store";
// import notesSagas from "./Notes";

export default function* rootSaga(getState) {
  yield all([
    // authSagas(),
    ...authSagas,
    ...orderSaga,
    ...commonSaga,
    ...memberSaga,
    ...storeSaga,
    // notesSagas()
  ]);
}
