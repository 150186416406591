import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isMobileDevice } from "../../../util/common-util";
import { springTransition } from "../../../const";
import { motion, AnimateSharedLayout } from "framer-motion";
import useAuth from "components/hook/useAuth";
import { ShoppingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { userSignOut } from "appRedux/actions/Auth";
import { MemberSelector } from "appRedux/selector";
import Notification from 'util/notification'
import { useSelector } from "react-redux";

const LotteryHeader = () => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const mobileToggleMenuRef = useRef();
  const shopList = useSelector(MemberSelector.shopList);

  function isMatch(currLocation) {
    return location.pathname === currLocation ? "active" : "";
  }

  function changeMenu(menuPath, state, noToggle) {
    return () => {
      if (isMobileDevice() && !noToggle) {
        mobileToggleMenuRef.current.click();
      }

      history.push(menuPath, state);
    };
  }

  function renderUnderline(isSelect) {
    return (
      isSelect && (
        <motion.div
          layoutId="underline"
          className="active-underline"
          initial={false}
          transition={springTransition}
        ></motion.div>
      )
    );
  }

  function signOut() {
    dispatch(userSignOut());
  }

  function gotoFirstShop() {
    const targetShop = shopList?.[0].shopId
    if (targetShop) history.push('/shop?psid=' + targetShop)
    else Notification.alertInfo('คุณยังไม่มีตัวแทน')
    changeMenu('/shop?psid=' + targetShop, undefined, true)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-lottery">
      <div className="container-fluid container-xl">
        <motion.button
          ref={mobileToggleMenuRef}
          className="navbar-toggler navbar-toggler-lottery my-2 "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          whileTap={{ scale: 0.97 }}
        >
          <i className="feather-icon icon-menu text-main"></i>
          <span className="txt-menu ms-2">เมนู</span>
        </motion.button>
        <span className="navbar-brand-logo">
          <img src={require("assets/images/logo_red-tiger.png")} alt="banner logo" />
        </span>

        <div id="navbarText"
          className="collapse navbar-collapse order-2 order-sm-2  order-md-2 order-lg-1">
          <AnimateSharedLayout transition={{ duration: 0.5 }}>
            <ul className="navbar-nav ms-auto">
              <li
                className={`nav-item ${isMatch("/main")}`}
                onClick={changeMenu("/main")}
              >
                <span className="nav-link">
                  <i className="feather-icon icon-home me-1"></i>หน้าหลัก
                </span>
                {renderUnderline(isMatch("/main"))}
              </li>
              <li
                className={`nav-item ${isMatch("/search-agent")}`}
                onClick={changeMenu("/search-agent")}
              >
                <span className="nav-link">
                  <i className="feather-icon icon-user-check me-1"></i>
                  <span>ตัวแทนจำหน่าย</span>
                </span>
                {renderUnderline(isMatch("/search-agent"))}
              </li>
              <li
                className={`nav-item ${isMatch("/complaint")}`}
                onClick={changeMenu("/complaint")}
              >
                <span className="nav-link">
                  <i className="feather-icon icon-book me-1"></i>ร้องเรียน
                </span>
                {renderUnderline(isMatch("/complaint"))}
              </li>
              <li
                className={`nav-item ${isMatch("/policy")}`}
                onClick={changeMenu("/policy")}
              >
                <span className="nav-link">
                  <i className="feather-icon icon-briefcase me-1"></i>
                  <span>นโยบายบริษัท</span>
                </span>
                {renderUnderline(isMatch("/policy"))}
              </li>
              <li
                className={`nav-item ${isMatch("/about-us")}`}
                onClick={changeMenu("/about-us")}
              >
                <span className="nav-link">
                  <i className="feather-icon icon-globe me-1"></i>
                  <span>เกี่ยวกับเรา</span>
                </span>
                {renderUnderline(isMatch("/about-us"))}
              </li>
            </ul>
          </AnimateSharedLayout>
        </div>

        {/* Login / Member */}
        <ul className="order-1 order-sm-1 menu-profile  order-md-1 order-lg-2">
          <li className="nav-item dropdown ">
            <span
              className="nav-link dropdown-toggle profile-list"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {isAuth ? (
                <>
                  <div className="icon-avatar me-1">
                    <i className="feather-icon icon-user"></i>
                  </div>
                  <span className="d-none-edit"></span>
                </>
              ) : (
                <>
                  <div className="icon-avatar me-1">
                    <i className="feather-icon icon-lock"></i>
                  </div>
                  <span className="d-none-edit">ล็อคอิน</span>
                </>
              )}
            </span>

            {isAuth ? (
              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-lottery"
                aria-labelledby="navbarDropdown"
              >
                <li className="pointer" onClick={changeMenu("/members-profile", { menu: "menus", }, true)} >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-menu me-2"></i>เมนู
                  </span>
                </li>
                <li className="pointer" onClick={changeMenu("/members-profile", { menu: "editProfile", }, true)} >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-user me-2"></i>โปรไฟล์
                  </span>
                </li>
                <li className="pointer" onClick={gotoFirstShop} >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-search me-2"></i>ค้นหาลอตเตอรี่
                  </span>
                </li>
                <li
                  className="pointer"
                  onClick={changeMenu("/members-profile", { menu: "dealerList", }, true)}
                >
                  <span className="dropdown-item">
                    <ShoppingOutlined
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        paddingBottom: "5px",
                        paddingRight: "10px",
                      }}
                    />
                    ซื้อเลขกับตัวแทนของคุณ
                  </span>
                </li>
                <li
                  className="pointer"
                  onClick={changeMenu("/members-profile", { menu: "order" }, true)}
                >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-file-text me-2"></i>
                    ประวัติการสั่งซื้อ
                  </span>
                </li>
                <li className="pointer" onClick={signOut}>
                  <span className="dropdown-item">
                    <i className="feather-icon icon-log-out me-2"></i>
                    <span>ออกจากระบบ</span>
                  </span>
                </li>
              </ul>
            ) : (
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lottery"
                aria-labelledby="navbarDropdown" >
                <li onClick={changeMenu("/sign-up", { deepLink: 'login' }, true)} style={{ cursor: "pointer" }} >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-user me-2"></i>เข้าสู่ระบบ
                  </span>
                </li>
                <li onClick={changeMenu("/sign-up", { deepLink: 'register' }, true)} style={{ cursor: "pointer" }} >
                  <span className="dropdown-item">
                    <i className="feather-icon icon-file-text me-2"></i>
                    สมัครสมาชิก
                  </span>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default LotteryHeader;
