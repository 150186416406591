import { takeLatest, call, put, select } from 'redux-saga/effects'
import { MemberAction } from '../actions/Member'
import { MemberActionType, REQUEST } from '../../const/ActionTypes'
import { MemberAPI } from '../services/Member'
import Notification from '../../util/notification'
import { OrderSelector } from 'appRedux/selector'

const getDetail = function* ({ payload }) {
  try {
    const response = yield call(MemberAPI.getDetail, payload)
    const member = {
      ...response.data.result
    }
    yield put(MemberAction.getDetailSuccess({ member }));
  } catch (error) {
    yield put(MemberAction.getDetailFailure(error));
  }
}

const updateMember = function* ({ payload, resolver }) {
  try {
    const response = yield call(MemberAPI.updateMember, payload)
    console.log(response)
    const status = response.data.status
    if (status === "0") {
      yield put(MemberAction.updateMemberSuccess(response.data.result));
      Notification.alertInfo("อัพเดทข้อมูลสำเร็จ")
      yield put(MemberAction.getDetail())
    } else {
      const errorMessage = response.data.message;
      yield put(MemberAction.updateMemberFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(MemberAction.updateMemberFailure(error));
  }
}

const rewardList = function* ({ payload }) {
  try {
    const response = yield call(MemberAPI.rewardList, payload)
    if (response.data.result) {
      let newObj = { rewardList: response.data.result };
      yield put(MemberAction.getRewardListSuccess(newObj))
    } else {
      const errorMessage = response.data.message;
      yield put(MemberAction.getRewardListFailure(errorMessage))
    }
  } catch (error) {
    yield put(MemberAction.getRewardListFailure(error));
  }
}

const shopDetail = function* ({ payload }) {
  try {
    const response = yield call(MemberAPI.shopDetail, payload)
    const shopDetail = {
      ...response.data.result
    }
    yield put(MemberAction.shopDetailSuccess({ shopDetail }));
  } catch (error) {
    yield put(MemberAction.shopDetailFailure(error));
  }
}

const getOrderList = function* (provider, { payload }) {
  try {
    // items
    const orderHistoryList = yield select(OrderSelector.items)
    const response = yield call(provider.api, payload)
    const responsePayload = response.data.result
    responsePayload.items = [...orderHistoryList, ...responsePayload.items]
    yield put(provider.success(responsePayload))
  } catch (error) {
    console.error(error)
    yield put(provider.failure(error))
  }
}

export default [
  takeLatest(MemberActionType.GET_DETAIL[REQUEST], getDetail),
  takeLatest(MemberActionType.UPDATE_MEMBER[REQUEST], updateMember),
  takeLatest(MemberActionType.REWARD_LIST[REQUEST], rewardList),
  takeLatest(MemberActionType.SHOP_DETAIL[REQUEST], shopDetail),
  takeLatest(MemberActionType.GET_ORDER_LIST[REQUEST], getOrderList, {
    api: MemberAPI.getOrderList,
    success: MemberAction.getOrderListSuccess,
    failure: MemberAction.getOrderListFailure,
  }),
];