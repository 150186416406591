import { takeLatest, call, put, select } from 'redux-saga/effects'
import { CommonActionType, REQUEST } from '../../const/ActionTypes'
import { convertToFullThaiFormat } from '../../util/common-util';
import { CommonAPI } from '../services/Common'
import { CommonAction } from '../actions/Common'

const getCurrentLotteryDate = function*() {
    try {
      let drawDateList = yield select(({ common }) => common.drawDateList);
      if ( !drawDateList || drawDateList.length === 0 ) {
        const response = yield call(CommonAPI.getCurrentLotteryDate);
        const drawDateList = response.data.result.map(date => ({ label: convertToFullThaiFormat(date), value: date }));
        yield put(CommonAction.getCurrentLotteryDateSuccess(drawDateList));
      }
    } catch (error) {
        yield put(CommonAction.getCurrentLotteryDateFailure(error));
    }
}
const getBankList = function*() {
  try {
      let bankList = yield select(({ common }) => common.bankList);
      if ( !bankList || bankList.length === 0 ) {
          const response = yield call(CommonAPI.getBankList);
          const bankList = response.data.result.map(b => ({ ...b, label: b.thaiName, value: `${b.bankPrefix}_${b.thaiName}`}))
          yield put(CommonAction.getBankListSuccess(bankList));
      }
  } catch (error) {
      yield put(CommonAction.getBankListFailure(error));
  }
}

export default [
    takeLatest(CommonActionType.GET_CURRENT_LOTTERT_DATE[REQUEST], getCurrentLotteryDate),
    takeLatest(CommonActionType.GET_BANK_LIST[REQUEST], getBankList),
];