export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc
  }, {});
}

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const REMOVE_USER_LOCAL = 'REMOVE_USER_LOCAL';
export const UNAUTHEN_401 = 'UNAUTHEN_401';
export const WATCH_SESSION_TERMINATE = 'WATCH_SESSION_TERMINATE';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Order
export const OrderActionType = {
  GET_ORDER_LIST: createRequestTypes('GET_ORDER_LIST'),
  ADD_ORDER: createRequestTypes('ADD_ORDER'),
  GET_ORDER_DETAIL: createRequestTypes('GET_ORDER_DETAIL'),
  UPLOAD_PROOF_PAYMENT: createRequestTypes('UPLOAD_PROOF_PAYMENT'),
  INIT_ORDERING: 'INIT_ORDERING',
}

export const CommonActionType = {
  GET_CURRENT_LOTTERT_DATE: createRequestTypes('GET_CURRENT_LOTTERT_DATE'),
  GET_BANK_LIST: createRequestTypes('GET_BANK_LIST'),
}

// Member
export const MemberActionType = {
  CHECK_EXSIST_MEMBER: createRequestTypes('CHECK_EXSIST_MEMBER'),
  REGISTER_MEMBER: createRequestTypes('REGISTER_MEMBER'),
  OTP_SEND: createRequestTypes('OTP_SEND'),
  OTP_APPROVE: createRequestTypes('OTP_APPROVE'),
  GET_DETAIL: createRequestTypes('GET_DETAIL'),
  UPDATE_MEMBER: createRequestTypes('UPDATE_MEMBER'),
  REWARD_LIST: createRequestTypes('REWARD_LIST'),
  SHOP_DETAIL: createRequestTypes('SHOP_DETAIL'),
  GET_ORDER_LIST: createRequestTypes('GET_ORDER_LIST'),
  GET_ORDER_DETAIL: createRequestTypes('GET_ORDER_DETAIL'),
}

export const StoreActionType = {
  SHOP_FETCH_LOTTERY: createRequestTypes('SHOP_FETCH_LOTTERY'),
  SHOP_FETCH_LOTTERY_SET: 'SHOP_FETCH_LOTTERY_SET',
  SHOP_LOTTERY_NUMBER_CHANGE: 'SHOP_LOTTERY_NUMBER_CHANGE',
  SHOP_ADD_LOTTERY_TO_CART: 'SHOP_ADD_LOTTERY_TO_CART',
  SHOP_REMOVE_LOTTERY_FROM_CART: 'SHOP_REMOVE_LOTTERY_FROM_CART',
  SHOP_CLEAR_ORDER: 'SHOP_CLEAR_ORDER',
}