import { MemberActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes'

export const MemberAction = {
    getDetail: payload => ({ type: MemberActionType.GET_DETAIL[REQUEST], payload }),
    getDetailSuccess: payload => ({ type: MemberActionType.GET_DETAIL[SUCCESS], payload }),
    getDetailFailure: payload => ({ type: MemberActionType.GET_DETAIL[FAILURE], payload }),


    updateMember: (payload, resolver) => ({ type: MemberActionType.UPDATE_MEMBER[REQUEST], payload, resolver }),
    updateMemberSuccess: payload => ({ type: MemberActionType.UPDATE_MEMBER[SUCCESS], payload }),
    updateMemberFailure: payload => ({ type: MemberActionType.UPDATE_MEMBER[FAILURE], payload }),

    getRewardList: payload => ({ type: MemberActionType.REWARD_LIST[REQUEST], payload }),
    getRewardListSuccess: payload => ({ type: MemberActionType.REWARD_LIST[SUCCESS], payload }),
    getRewardListFailure: payload => ({ type: MemberActionType.REWARD_LIST[FAILURE], payload }),

    shopDetail: payload => ({ type: MemberActionType.SHOP_DETAIL[REQUEST], payload }),
    shopDetailSuccess: payload => ({ type: MemberActionType.SHOP_DETAIL[SUCCESS], payload }),
    shopDetailFailure: payload => ({ type: MemberActionType.SHOP_DETAIL[FAILURE], payload }),

    getOrderList: payload => ({ type: MemberActionType.GET_ORDER_LIST[REQUEST], payload }),
    getOrderListSuccess: payload => ({ type: MemberActionType.GET_ORDER_LIST[SUCCESS], payload }),
    getOrderListFailure: error => ({ type: MemberActionType.GET_ORDER_LIST[FAILURE], error }),
}