import { AuthSelector } from 'appRedux/selector';
// import { _TOKEN_ } from 'const';
import React, { memo } from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({component: Component, ...rest}) => {
  const token = useSelector(AuthSelector.token)
  // const isAuthened = !!localStorage.getItem(_TOKEN_);
  return (
    <Route {...rest} render={(
      props => token 
        ? <Component {...props} /> 
        : <Redirect to={{ pathname: '/sign-up' }}/>
    )}/>
  )
}

export default memo(AuthRoute);