import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";

const App = () => {

  const { locale } = useSelector(({ settings }) => settings);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (location.pathname === '/') {
      history.replace('/main');
    }
  }, [location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale="th-TH" messages={currentAppLocale.messages} >
        <Switch>
          <Route path={`${match.url}`} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
