import AuthRoute from "components/Routes/AuthRoute";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
// import LoadingPageComponent from "components/LoadingPageComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={LazyLoad(lazy(() => import('./Main')))} />
      <Route path={`${match.url}search-agent`} component={LazyLoad(lazy(() => import('./SearchAgent')))} />
      <Route path={`${match.url}complaint`} component={LazyLoad(lazy(() => import('./Complaint')))} />
      <Route path={`${match.url}policy`} component={LazyLoad(lazy(() => import('./Policy')))} />
      <Route path={`${match.url}about-us`} component={LazyLoad(lazy(() => import('./AboutUs')))} />
      <Route path={`${match.url}order`} component={LazyLoad(lazy(() => import('./Order/Provider/Lottery')))} />
      <Route path={`${match.url}order-lottery-box`} component={LazyLoad(lazy(() => import('./Order/Provider/LotteryBox')))} />
      <Route path={`${match.url}order-detail`} component={LazyLoad(lazy(() => import('./Order/Provider/OrderLotteryDetail')))} />
      <Route path={`${match.url}order-lottery-box-detail`} component={LazyLoad(lazy(() => import('./Order/Provider/OrderLotteryBoxDetail')))} />
      <Route path={`${match.url}search-lottery`} component={LazyLoad(lazy(() => import('./SearchLottery')))} />
      <Route path={`${match.url}shop`} component={LazyLoad(lazy(() => import('./Shop')))} />
      <Route path={`${match.url}cart`} component={LazyLoad(lazy(() => import('./Cart')))} />
      <AuthRoute path={`${match.url}members-profile`} component={LazyLoad(lazy(() => import('./Profile')))} />
      <AuthRoute path={`${match.url}members-order-detail`} component={LazyLoad(lazy(() => import('./Order/Provider/MemberOrderDetail')))} />
      <Route path={`${match.url}sign-up`} component={LazyLoad(lazy(() => import('./SignUpForm')))} />
      <Route path={`${match.url}transfer-channel`} component={LazyLoad(lazy(() => import('./TransferChannel')))} />
    </Switch>
  </div>
);

export default React.memo(App);

function LazyLoad(LazyComponent) {
  return props => (
    // <Suspense fallback={<LoadingPageComponent/>}>
    <Suspense fallback={''}>
      <LazyComponent {...props} />
    </Suspense>
  );
}