import { call, put, select, takeLatest } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER, } from "const/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from "../../appRedux/actions/Auth";
import { AuthAPI } from "../services/Auth";
import { _TOKEN_ } from "../../const";
import { push, replace } from 'react-router-redux';
import { UNAUTHEN_401 } from "../../const/ActionTypes";
import Notification from '../../util/notification'
import { AuthSelector } from "appRedux/selector";

function* signIn({ payload }) {
  try {
    const { redirectUrl, locationState, ...credential } = payload;
    const initURL = redirectUrl ?? (yield select(AuthSelector.initURL));
    const response = yield call(AuthAPI.signin, credential);
    const { accessToken, tokenType, approve } = response.data.result;
    if (approve) {
      const token = `${tokenType} ${accessToken}`;
      yield put(userSignInSuccess({ [_TOKEN_]: token }));
      yield put(replace(initURL, locationState));
    } else {
      yield put(replace('sign-up', { deepLink: 'otp', mobile: credential.username }));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    yield put(userSignOutSuccess());
    yield put(push('/main'));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* unAuthen401() {
  // if (localStorage.getItem(_TOKEN_)) {
    // yield put(removeUserLocal());
    yield put(userSignOutSuccess());
    yield call(signOut);
    Notification.alertError('ไม่อยู่หน้าจอเป็นเวลานาน กรุณาล็อคอินใหม่');
  // }
}


const saga = [
  takeLatest(SIGNIN_USER, signIn),
  takeLatest(SIGNOUT_USER, signOut),
  takeLatest(UNAUTHEN_401, unAuthen401),
]

export default saga;
