import { LotteryClientAPI } from './LotteryCreator'

export const MemberAPI = {
  checkExist: params => LotteryClientAPI.get('/auth/check', { params }),
  register: payload => LotteryClientAPI.post('/auth/register', payload),
  otpSend: params => LotteryClientAPI.get('/auth/otp/send', { params }),
  otpApprove: payload => LotteryClientAPI.post('/auth/otp/approve', payload),
  getDetail: params => LotteryClientAPI.get('/member', { params }),
  updateMember: payload => LotteryClientAPI.post('/member', payload),
  rewardList: payload => LotteryClientAPI.post('/member/reward/histories', payload),
  shopDetail: params => LotteryClientAPI.get('/shop', { params }),

  uploadProofPayment: payload => LotteryClientAPI.post('/member/order/upload', payload),
  getOrderList: payload => LotteryClientAPI.post('/member/order/', payload),
  getMemberOrderDetail: params => LotteryClientAPI.get('/member/order/detail', { params }),

  bookingOrder: payload => LotteryClientAPI.post('/member/order/add', payload),
  getCustomerByTel: (params, cancelToken) => LotteryClientAPI.get('/member/customer', { params, cancelToken }),
  checkRewardSlip: payload => LotteryClientAPI.post('/member/reward/slips', payload),
}