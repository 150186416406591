import { _TOKEN_ } from "const"
import { createSelector } from 'reselect'

export const AuthSelector = {
  state: state => state.auth,
  token: state => AuthSelector.state(state)[_TOKEN_],
  initURL: state => AuthSelector.state(state).initURL,
}

export const CommonSelector = {
  common: ({ common }) => common,
  bankList: state => CommonSelector.common(state).bankList,
  drawDateList: state => CommonSelector.common(state).drawDateList,
}

export const OrderSelector = {
  order: ({ order }) => order,
  loading: state => OrderSelector.order(state).loading,
  uploading: state => OrderSelector.order(state).uploading,
  orderDetail: state => OrderSelector.order(state).orderDetail,
  orderSuccessPayload: state => OrderSelector.order(state).orderSuccessPayload,
  items: state => OrderSelector.order(state).items,
  totalItem: state => OrderSelector.order(state).totalItem,
  totalPage: state => OrderSelector.order(state).totalPage ?? 0,
  lottoImages: state => OrderSelector.order(state).lottoImages,
}

export const MemberSelector = {
  member: ({ member }) => member,
  loading: state => MemberSelector.member(state).loading,
  rewardList: state => MemberSelector.member(state).rewardList,
  shop: state => MemberSelector.member(state).shopDetail,
  shopList: state => MemberSelector.member(state).member?.shopList,
  isShopOpen: state => {
    const isOpen = MemberSelector.member(state).shopDetail.isOpen
    const openStatus = MemberSelector.member(state).shopDetail.openStatus
    return (openStatus || '').toUpperCase() === 'Y' && isOpen;
  },
  priceConfig: state => MemberSelector.shop(state).priceConfig,
}

export const StoreSelector = {
  state: state => state.store,
  loading: state => StoreSelector.state(state).loading,
  lotteries: state => StoreSelector.state(state).lotteries,
  cart: createSelector(
    state => StoreSelector.state(state).cart,
    state => MemberSelector.priceConfig(state),
    (cart, priceConfig) => cart.map(lottery => {
      const serviceChargePerUnit = lottery.isBigSet 
                                    ? priceConfig.bigGroup ?? 0
                                    : priceConfig.smallGroup.find(p => +p.lotterySet === +lottery.count)?.service ?? 0;
      const serviceCharge = lottery.isBigSet 
                              ? lottery.count * serviceChargePerUnit
                              : lottery.count * serviceChargePerUnit;
      return {
        ...lottery, 
        serviceCharge,
        serviceChargePerUnit,
      }
    })
  ),
  bigSetLotteries: state => StoreSelector.state(state).bigSetLotteries,
  lotteryNumber: state => StoreSelector.state(state).lotteryNumber,
  numberSet: state => StoreSelector.state(state).numberSet,
  paginable: state => StoreSelector.state(state).paginable,
  done: state => {
    const lotteries = StoreSelector.lotteries(state);
    const total = StoreSelector.paginable(state).total;
    return lotteries?.length >= total || total === 0;
  },
}
