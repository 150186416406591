// import { _TOKEN_ } from '../../const';
// import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { AuthSelector } from 'appRedux/selector';

const useAuth = () => {
  const token = useSelector(AuthSelector.token)
  // const [isAuth, setIsAuth] = useState(localStorage.getItem(_TOKEN_));
  // useEffect(() => {
  //   setIsAuth(localStorage.getItem(_TOKEN_));
  // }, []);

  return token;
}

export default useAuth;