import { StoreActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
import ImmuUtil from 'util/immu'
import _ from 'lodash'

const paginable = {
  page: 0,
  perPage: 50,
  total: 0,
}

const initialStateClearSearch = {
  bigGroupText: undefined,
  bigGroupQuantity: undefined,
  lotteries: undefined,
  bigSetLotteries: undefined,
  isOpenSell: null,
  paginable: { ...paginable }
}

const initialState = {
  ...initialStateClearSearch,
  lotteryNumber: '',      // number filter
  numberSet: undefined, // set filter
  loading: false,
  cart: [],
};

export default (state = initialState, { type, payload = {}, error }) => {
  switch (type) {
    case StoreActionType.SHOP_FETCH_LOTTERY[REQUEST]: {
      if (payload.init) {
        return {
          ...state,
          ...initialStateClearSearch,
          // numberSet: undefined,
          loading: true,
        }
      } else {
        return { ...state, loading: true }
      }
    }

    case StoreActionType.SHOP_FETCH_LOTTERY_SET:
      return {
        ...state,
        ...initialStateClearSearch,
        numberSet: payload.numberSet,
        // lotteryNumber: '',
        loading: true,
      }
    
    case StoreActionType.SHOP_FETCH_LOTTERY[SUCCESS]:
      return {
        ...state,
        ...payload,
        loading: false,
        lotteries:
          _.uniqBy(
            _.compact(
              _.concat(
                state.lotteries,
                _.differenceBy(payload.lotteries, state.lotteries, 'id')
                  .map(lottery => {
                    if (_.some(state.cart, ['id', lottery.id])) {
                      lottery.selected = true;
                    }
                    return lottery;
                  })
              )
            ), 'id'
          ),
      }

    case StoreActionType.SHOP_FETCH_LOTTERY[FAILURE]:
      return { ...state, loading: false, error }

    case StoreActionType.SHOP_LOTTERY_NUMBER_CHANGE:
      return { ...state, lotteryNumber: payload }

    case StoreActionType.SHOP_ADD_LOTTERY_TO_CART: {
      const alreadySelect = state.cart.some(l => l.id === payload.id)
      if (alreadySelect) {
        return state;
      }
      if (payload.isBigSet) {
        return ImmuUtil.update(state,
          { action: ImmuUtil.SET, path: 'cart', state: [payload] },
          ...(() => {
            const updateCOnfig = state.lotteries.filter(({ selected }) => selected).map(lottery => (
              { action: ImmuUtil.MERGE, path: 'lotteries.{id}', state: { selected: false }, pathParams: lottery }
            ))
            return updateCOnfig.length > 0 ? updateCOnfig : [];
          })(),
        )
      } else {
        return ImmuUtil.update(state,
          { action: ImmuUtil.REMOVE, path: 'cart', state: { index: cart => cart.map((l, index) => l.isBigSet ? index : null) } },
          { action: ImmuUtil.PUSH, path: 'cart', state: payload, },
          { action: ImmuUtil.MERGE, path: 'lotteries.{id}', state: { selected: true }, pathParams: payload, },
        )
      }
    }

    case StoreActionType.SHOP_REMOVE_LOTTERY_FROM_CART:
      return ImmuUtil.update(state,
        {
          action: ImmuUtil.REMOVE,
          path: 'cart',
          state: { index: (lotteries) => lotteries.findIndex(lottery => lottery.id === payload.id) }
        },
        !payload.isBigSet ? {
          action: ImmuUtil.MERGE,
          path: 'lotteries.{id}',
          state: { selected: false },
          pathParams: payload,
        } : null,
      )

    case StoreActionType.SHOP_CLEAR_ORDER:
      return {
        ...initialState
        // lotteries: [
        //   ...state.lotteries.map(lottery => {
        //     if (lottery.selected) {
        //       lottery.selected = false;
        //       return { ...lottery };
        //     }
        //     return lottery;
        //   })
        // ]
      }

    default:
      return state;
  }
}
