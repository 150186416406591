import React from "react";
import { Layout } from "antd";
import App from "routes/index";
import { useDispatch, useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../const/ThemeSetting";
import { useRouteMatch } from "react-router-dom";
import LotteryHeader from "../Topbar/LotteryHeader";
import { motion } from 'framer-motion';
import LoginModal from "components/LoginModal";
import BottomFloat from "components/BottomFloat";
import AdsModal from "containers/AdsModal";

const { Content, Footer } = Layout;

const MainApp = () => {

  const dispatch = useDispatch();
  const { swIgnore, swUpdate, register } = useSelector(({ common }) => common.serviceWorker);
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();

  const updateServiceWorker = () => {
    const registrationWaiting = register.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload()
        }
      });
    }
  };

  const ignoreUpdateServiceWorker = () => {
    dispatch({ type: 'SERVICE_WORKER_IGNORED' })
  }

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };

  return (
    <LoginModal>
      <Layout className="gx-app-layout">
        <Layout style={{ backgroundColor: '#faf9f9' }}>
          <LotteryHeader />
          <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
            <App match={match} />
            <AdsModal/>
          </Content>
          <Footer className="d-none-edit">
            <div className="container">
              <div className="text-center">
                สงวนลิขสิทธิ์ พ.ศ.2563 เสือแดง ลอตเตอรี่ ออนไลน์
            </div>
            </div>
          </Footer>
        </Layout>

        <BottomFloat />

        {swUpdate && !swIgnore && (
          <motion.div className="reload-control"
            variants={serviceWorkerVariants} initial="hidden" animate="visible"
            exit={{ scale: 0, transition: { duration: .2 } }}>
            <div className="bg-reload">
              <span className="close-reload pointer" onClick={ignoreUpdateServiceWorker}>
                <i className="feather-icon icon-x"></i>
              </span>
              <div className="d-flex ">
                <div className="img-reload">
                  <img src={require("assets/images/img-reload.svg")} alt="bell for update website" />
                </div>
                <div>
                  <h5 className="mb-2 mb-lg-3  mb-md-3">เว็บไซต์มีการอัพเดทคลิกที่นี่เพื่อรับการอัพเดท</h5>
                  <button className="btn btn-lottery mb-2 px-4" onClick={updateServiceWorker}><span>อัพเดททันที</span></button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </Layout>
    </LoginModal>
  )
};
export default MainApp;

const serviceWorkerVariants = {
  hidden: { opacity: 0, x: 300, y: 300 },
  visible: { opacity: 1, x: 0, y: 0 },
}