import { useCallback } from "react";
import { useState } from "react";

const useControlVisible = (defaultVisible = false) => {
  const [visible, setVisible] = useState(defaultVisible);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  return [visible, setVisible, [show, hide]]
}

export default useControlVisible;