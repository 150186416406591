import { takeLatest, call, put, select } from 'redux-saga/effects'
import { StoreAction } from '../actions/Store'
import { StoreActionType, REQUEST } from '../../const/ActionTypes'
import { convertLotteryNumberToSearchParams, convertToFullEngFormat, convertToFullThaiFormat } from 'util/common-util'
import { CommonSelector, StoreSelector } from 'appRedux/selector'
import { LotteryAPI } from 'appRedux/services/Lottery'
// import Notification from '../../util/notification'

const fetchLottery = function* ({ onComplete }) {
  try {
    const currentLotteryDate = (yield select(CommonSelector.drawDateList))[0].value;
    const lotteryNumber = yield select(StoreSelector.lotteryNumber)
    const numberSet = yield select(StoreSelector.numberSet)

    let paginable = {...yield select(StoreSelector.paginable)};
    paginable.page += 1;
    const criteria = convertLotteryNumberToSearchParams(lotteryNumber, paginable, { currentLotteryDate, numberSet });

    const response = yield call(LotteryAPI.loadLottery, criteria);
    const {
      bigGroupText, bigGroupQuantity, totalItem: total,
      isOpenSell, lotteries,
    } = response.data.result;
    const bigSetLotteries = prepareBigSetLotteryData(currentLotteryDate, bigGroupText, bigGroupQuantity)

    if (lotteries.length > 0) {
      paginable.total = total;
      yield put(StoreAction.fetchLotterySuccess({ 
        bigGroupText, bigGroupQuantity, isOpenSell,
        lotteries, bigSetLotteries, paginable,
      }));
    } else {
      yield put(StoreAction.fetchLotteryFailure());
    }

  } catch (error) {
    console.log(error)
    yield put(StoreAction.fetchLotteryFailure(error));
  } finally {
    onComplete && onComplete()
  }
}

function prepareBigSetLotteryData(currentLotteryDate, bigGroupText, bigGroupQuantity) {
  const dateTh = convertToFullThaiFormat(currentLotteryDate);
  const dateEn = convertToFullEngFormat(currentLotteryDate);
  const bigSetLotteries = getBigSetLottery(bigGroupQuantity).map(
    count => ({
      count,
      id: bigGroupText,
      isBigSet: true,
      dateTh, dateEn,
    })
  );
  return bigSetLotteries;
}

function getBigSetLottery(bigGroupQuantity) {
  if (bigGroupQuantity >= 5) {
    const bigGroups = [500, 200, 100, 50, 20, 10, 5];
    if (!bigGroups.includes(bigGroupQuantity)) {
      bigGroups.unshift(bigGroupQuantity)
    }
    return bigGroups.reduce((acc, amount) => {
      return ((bigGroupQuantity >= amount && acc.push(amount), acc));
    }, []);
  } else {
    return [];
  }
}

export default [
  takeLatest([
    StoreActionType.SHOP_FETCH_LOTTERY[REQUEST],
    StoreActionType.SHOP_FETCH_LOTTERY_SET,
  ], fetchLottery),
];
